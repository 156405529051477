<header class="flix-header">
    <div class="flix-header__inner">
        <div class="flix-header-brand flix-header-brand--square">
            <a class="flix-header-brand__link" href="/">
                <img alt="Taxus Logo" class="flix-header-brand__img" height="36" width="36"
                     ngSrc="/assets/taxus_logo.svg"/>
            </a>
        </div>
        <nav aria-label="Main" class="flix-header-nav">
            <ul class="flix-header-nav__list">
                <li class="flix-header-nav__item">
                    <a class="flix-header-nav__link"
                       routerLink="/franchise-tax-rule"
                       routerLinkActive="active"
                       ariaCurrentWhenActive="page">
                        <i aria-hidden="true" class="flix-icon flix-icon-finance-solid"></i>
                        <span class="flix-header-nav__text">Tax Rule (Franchise)</span>
                    </a>
                </li>
                <li class="flix-header-nav__item">
                    <a class="flix-header-nav__link"
                       routerLink="/b2c"
                       routerLinkActive="active"
                       ariaCurrentWhenActive="page">
                        <i aria-hidden="true" class="flix-icon flix-icon-cash-solid"></i>
                        <span class="flix-header-nav__text">B2C Taxes</span>
                    </a>
                </li>
            </ul>
        </nav>

        <div class="flix-header-widgets">
            <div class="flix-header-user-widget">
                <ng-container *ngIf="isAuthenticated">
                    <em class="flix-icon flix-icon--size-6 flix-icon-person-business-solid"></em>
                    <span class="flix-header-user-widget__name">{{ userName }}</span>
                </ng-container>
            </div>
        </div>
    </div>
</header>