import {Component, OnInit} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {Logger} from '@azure/msal-browser';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {environment} from '../environments/environment';
import {HeaderComponent} from './shared/component/header/header.component';
import {GridDirective} from "./honeycomb/grid/grid.directive";
import {ColumnDirective} from "./honeycomb/grid/column.directive";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, HeaderComponent, GridDirective, ColumnDirective],
})
export class AppComponent implements OnInit {
    constructor(private authService: MsalService) {}

    ngOnInit() {
        if (!environment.production) {
            this.authService.setLogger(new Logger({
                loggerCallback: (_level, message, containsPii) => {
                    if (containsPii) {
                        console.log('MSAL Logging: ######(PII)');
                    } else {
                        console.log('MSAL Logging: ', message);
                    }
                }
            }));
        }
    }
}
